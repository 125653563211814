<template>
  <div class="product_service_container">
    <p class="title">Forcecon-DTwin&nbsp;&nbsp;1.0</p>
    <p class="title2">专注于工业数字孪生PaaS平台</p>
    <p class="titl">
      工艺规划配置&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;漫游巡检&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;虚实联动&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;数字可视化
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.product_service_container {
  width: 1920px;
  height: 567px;
  background-image: url("../../assets/images/chanpinfuwu-banner@2x.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-top: 211px;
  padding-left: 375px;
}
.product_service_container p {
  color: #ffffff;
}
.product_service_container p:nth-child(1) {
  font-size: 30px;
  font-weight: bold;
  font-family: SourceHanSansCN-Bold;
}
.product_service_container p:nth-child(2) {
    font-size: 32px;
      font-weight: bold;
      font-family: SourceHanSansCN-Bold;
      margin-top: 27px;
}
.product_service_container p:nth-child(3) {
    font-size: 16px;
      font-weight: 400;
      font-family: SourceHanSansCN-Normal;
      margin-top: 38px;
      color: #f2f2f2;
}
</style>