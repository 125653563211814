import { render, staticRenderFns } from "./product-frame-introduction.vue?vue&type=template&id=00a6f292&scoped=true&"
import script from "./product-frame-introduction.vue?vue&type=script&lang=js&"
export * from "./product-frame-introduction.vue?vue&type=script&lang=js&"
import style0 from "./product-frame-introduction.vue?vue&type=style&index=0&id=00a6f292&prod&lang=css&"
import style1 from "./product-frame-introduction.vue?vue&type=style&index=1&id=00a6f292&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00a6f292",
  null
  
)

export default component.exports