<template>
    <div>
      <section class="pc_container">
        <banner></banner>
        <quickStart></quickStart>
      </section>
      <section class="mobile_container">
        <mobile-product-description></mobile-product-description>
      </section>
    </div>
  </template>
  
  <script>
  import banner from "@/components/productDescription/banner.vue";
  import quickStart from "@/components/productDescription/quickStart.vue";
  import mobileProductDescription from "@/components/mobile/productDescription/mobile-productDescription.vue";
  export default {
    components: {
      banner,
      quickStart,
      mobileProductDescription
    },
    mounted() {
      window.scrollTo(0, 0);
    }
  };
  </script>
  
  <style></style>