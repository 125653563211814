import { render, staticRenderFns } from "./nav.vue?vue&type=template&id=dcc391a6&scoped=true&"
import script from "./nav.vue?vue&type=script&lang=js&"
export * from "./nav.vue?vue&type=script&lang=js&"
import style0 from "./nav.vue?vue&type=style&index=0&id=dcc391a6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dcc391a6",
  null
  
)

export default component.exports