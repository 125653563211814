<template>
  <section class="mobile_product_description_banner">
            <p class="mobile_product_description_banner_title">新手指引</p>
            <p class="mobile_product_description_banner_desc">通过快速入门教程，实践案例，视频教程等各项操作指南，帮助新用户更快的了解产品的使用。</p>
        </section>
</template>

<script>
export default {

}
</script>

<style scoped>
.mobile_product_description_banner {
    width: 100%;
    height: 854.4px;
    background-image: url('../../../assets/images/mobile_product_description_banner.png');
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 213.6px;
    padding: 250.98px 328.41px 256.32px 61.41px;
}

.mobile_product_description_banner_title {
    /* width: 139px; */
    height: 93.45px;
    font-size: 96.12px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 64.08px;
}

.mobile_product_description_banner_desc {
    width: 1602px;
    height: 157.53px;
    font-size: 64.08px;
    font-family: Source Han Sans CN;
    font-weight: 300;
    color: #FFFFFF;
    line-height: 96.12px;
    margin-top: 96.12px;
}
</style>