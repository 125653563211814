import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: () => import('@/components/show.vue'),
  // },
  // {
  //   path: '/product-service',
  //   name: 'productService',
  //   component: () => import('@/views/product-service/index.vue')
  // },
  // {
  //   path: '/product-description',
  //   name: 'productDescription',
  //   component:  () => import('@/views/product-description/index.vue')
  // },
  // {
  //   path: '/solution',
  //   name: 'solution',
  //   component:  () => import('@/views/solution/index.vue')
  // },
  // {
  //   path: '/application-case',
  //   name: 'applicationCase',
  //   component:  () => import('@/views/application-case/index.vue')
  // },
  // {
  //   path: '/contact',
  //   name: 'contact',
  //   component: () => import('@/views/contact/index.vue')
  // },
  // {
  //   path: '/product-description/product-frame-introduction',
  //   name: 'productDescriptionDetail',
  //   component:  () => import('@/views/product-description/product-frame-introduction.vue')
  // },
  // {
  //   path: '/product-description/core-function',
  //   name: 'productDescriptionDetail',
  //   component:  () => import('@/views/product-description/product-frame-introduction.vue')
  // },
  // {
  //   path: '/product-description/3d-explain',
  //   name: 'productDescriptionDetail',
  //   component:  () => import('@/views/product-description/product-frame-introduction.vue')
  // },
  // {
  //   path: '/product-description/ui-explain',
  //   name: 'productDescriptionDetail',
  //   component:  () => import('@/views/product-description/product-frame-introduction.vue')
  // },
  // {
  //   path: '/product-description/app-explain',
  //   name: 'productDescriptionDetail',
  //   component:  () => import('@/views/product-description/product-frame-introduction.vue')
  // },
  // {
  //   path: '/product-description/indevelop',
  //   name: 'productDescriptionDetail',
  //   component:  () => import('@/views/product-description/product-frame-introduction.vue')
  // },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
