<template>
    <div class="home_page_container">
      <section class="pc_container">
        <section class="home_banner">
          <swiper :findBannerList="homeBannerArr" showType="homePageBanner"></swiper>
        </section>
  
        <section class="show_content_item_box luanshengpingtai">
          <h1 class="nowarp">
            <img class="biaoti_circle" src="../../assets/images/biaoti-qiu@2x.png" alt="" />
            ForceCon-DTwin力控数字孪生平台
          </h1>
          <p class="nowarp">
            多形式数据图表展示、设备管理、权限管理、监控可视化、告警可视化、运行可视化、操作可视化、门禁可视化、应用配置
          </p>
          <div class="img_show_box"></div>
        </section>
  
        <section class="show_content_item_box show_content_item_box_no_bg">
          <h1 class="show_title">ForceCon-DTwin力控数字孪生平台架构</h1>
          <div class="img_scroll_out_box">
            <div class="img_scroll_box">
              <img class="title_qiu" src="../../assets/images/ping_tai_jia_gou.png" alt="" />
            </div>
          </div>
          <div class="wangge"></div>
        </section>
        <section class="light_point_container">
          <h1>ForceCon-DTwin力控数字孪生平台亮点</h1>
          <div class="show_content">
            <swiper :findBannerList="lightPointArr" showType="lightPoint"></swiper>
          </div>
        </section>
        <section class="solution_container">
          <p class="solution_title">解决方案</p>
          <div class="solution_swiper_box">
            <swiper :findBannerList="solutionArr" showType="isSolution"></swiper>
          </div>
        </section>
      </section>
      <section class="mobile_container">
        <mobileHome :lightPointArr="lightPointArr" />
      </section>
    </div>
  </template>
  
  <script>
  import swiper from "@/components/common/swiper.vue";
  import mobileHome from '@/components/mobile/home/mobile-home.vue'
  export default {
    name: "homePage",
    components: {
      swiper,
      mobileHome
    },
    data() {
      this.swiperList = [
        { name: "one", img: require("../../assets/images/pingtailiangdian.png") },
        {
          name: "two",
          img: require("../../assets/images/shouye-pingtailiangdian-3.png"),
        },
      ];
      this.homeBannerArr = [
        {
          name: "one",
          imgName: "shouye-banner.png",
          imgUrl: "shouye_banner_1.jpg",
          // imgUrl: "shouye_banner_2.png",
          title: "低代码数字孪生可视化平台",
          titleEn: "LOW CODE DIGITAL TWIN VISUALIZATION PLATFORM",
          desc: "工艺规划配置 · 漫游巡检 · 虚实联动 · 数字可视化",
        },
        {
          name: "two",
          imgName: "showye-banner2.jpg",
          imgUrl: "shouye_banner_2.png",
          title: "一站式实时云渲染PaaS解决方案",
          titleEn: "ONE STOP REAL-TIME CLOUD RENDERING PAAS SOLUTION",
          desc: "支持大规模3D模型进行实时展示和交互，增强部署的灵活性",
        },
      ];
      this.lightPointArr = [
        {
          name: "one",
          imgUrl: require("../../assets/images/pingtailiangdian_1.png"),
          title: "数字孪生的工艺规划",
          desc1:
            "借助自研的DTwin-IDE开发平台，真正意义上实现了面向生产现场的工艺过程建模与仿真",
          desc2:
            "在基于知识的工艺设计方面，实现了基于大数据分析的工艺知识建模、决策与优化",
          desc3:
            "工艺问题主动响应方面，由原先的被动响应向主动应对转变，实现了工艺问题的自主决策",
          desc4: "现场实时数据的问题预测、参数动态调整、过程迭代优化和决策制定等",
          iconUrl1: require("../../assets/images/ptld1-1@2x.png"),
          iconUrl2: require("../../assets/images/ptld1-2@2x.png"),
          iconUrl3: require("../../assets/images/ptld1-3@2x.png"),
          iconUrl4: require("../../assets/images/ptld1-4@2x.png"),
        },
        {
          name: "two",
          imgUrl: require("../../assets/images/pingtailiangdian_2.png"),
          title: "数字孪生的连接与集成标准",
          desc1:
            "规范数字孪生中物理实体、虚拟实体、服务与数据库的连接映射相关技术要求。",
          desc2:
            "规范服务与数据库之间的数据传输相关技术要求，包括传输协议、传输实时性等",
          desc3:
            "规范交互与集成相关技术要求，包括物理实体与虚拟实体交互、物理实体与孪生数据交互等",
          desc4:
            "规范连接测试相关技术要求，包括连接兼容性测试、连接可靠性测试、连接时间敏感性等",
          iconUrl1: require("../../assets/images/ptld2-1@2x.png"),
          iconUrl2: require("../../assets/images/ptld2-2@2x.png"),
          iconUrl3: require("../../assets/images/ptld2-3@2x.png"),
          iconUrl4: require("../../assets/images/ptld2-4@2x.png"),
        },
        {
          name: "three",
          imgUrl: require("../../assets/images/pingtailiangdian_3.png"),
          title: "数字孪生的全行业解决方案",
          desc1: "全要素、全流程、全业务的集成与融合的产品应用核心",
          desc2:
            "自研的物联网、互联网、等信息手段，将人、机、物、等要素全面接入ForceCon-DTwin",
          desc3:
            "通过关联、组合等作用，物理世界的实时运行状态数据在一定准则下被加以自动分析",
          desc4:
            "物理世界中的各种业务被有效集成，实现数据共享，消除信息孤岛，整体上提高了效率",
          iconUrl1: require("../../assets/images/ptld3-1@2x.png"),
          iconUrl2: require("../../assets/images/ptld3-2@2x.png"),
          iconUrl3: require("../../assets/images/ptld3-3@2x.png"),
          iconUrl4: require("../../assets/images/ptld3-4@2x.png"),
        },
      ];
      this.mobileLightPointArr = [
        {
          name: "one",
          imgUrl: require("../../assets/images/pingtailiangdian_1.png"),
          title: "数字孪生的工艺规划",
          desc1:
            "借助自研的DTwin-IDE开发平台，真正意义上实现了面向生产现场的工艺过程建模与仿真",
          desc2:
            "在基于知识的工艺设计方面，实现了基于大数据分析的工艺知识建模、决策与优化",
          desc3:
            "工艺问题主动响应方面，由原先的被动响应向主动应对转变，实现了工艺问题的自主决策",
          desc4: "现场实时数据的问题预测、参数动态调整、过程迭代优化和决策制定等",
          iconUrl1: require("../../assets/images/ptld1-1@2x.png"),
          iconUrl2: require("../../assets/images/ptld1-2@2x.png"),
          iconUrl3: require("../../assets/images/ptld1-3@2x.png"),
          iconUrl4: require("../../assets/images/ptld1-4@2x.png"),
        },
        {
          name: "two",
          imgUrl: require("../../assets/images/pingtailiangdian_2.png"),
          title: "数字孪生的连接与集成标准",
          desc1:
            "规范数字孪生中物理实体、虚拟实体、服务与数据库的连接映射相关技术要求。",
          desc2:
            "规范服务与数据库之间的数据传输相关技术要求，包括传输协议、传输实时性等",
          desc3:
            "规范交互与集成相关技术要求，包括物理实体与虚拟实体交互、物理实体与孪生数据交互等",
          desc4:
            "规范连接测试相关技术要求，包括连接兼容性测试、连接可靠性测试、连接时间敏感性等",
          iconUrl1: require("../../assets/images/ptld2-1@2x.png"),
          iconUrl2: require("../../assets/images/ptld2-2@2x.png"),
          iconUrl3: require("../../assets/images/ptld2-3@2x.png"),
          iconUrl4: require("../../assets/images/ptld2-4@2x.png"),
        },
        {
          name: "three",
          imgUrl: require("../../assets/images/pingtailiangdian_3.png"),
          title: "数字孪生的全行业解决方案",
          desc1: "全要素、全流程、全业务的集成与融合的产品应用核心",
          desc2:
            "自研的物联网、互联网、等信息手段，将人、机、物、等要素全面接入ForceCon-DTwin",
          desc3:
            "通过关联、组合等作用，物理世界的实时运行状态数据在一定准则下被加以自动分析",
          desc4:
            "物理世界中的各种业务被有效集成，实现数据共享，消除信息孤岛，整体上提高了效率",
          iconUrl1: require("../../assets/images/ptld3-1@2x.png"),
          iconUrl2: require("../../assets/images/ptld3-2@2x.png"),
          iconUrl3: require("../../assets/images/ptld3-3@2x.png"),
          iconUrl4: require("../../assets/images/ptld3-4@2x.png"),
        },
      ];
      
      this.solutionArr = [
        {
          name: "one",
          imgUrl: require("../../assets/images/zhihuishuiwu.png"),
          path: "water",
          title: "智慧水务数字孪生解决方案",
          desc: "智慧水务数字孪生解决方案是将水资源管理、水环境保护、供水、排水等水务领域的实体系统与数字化系统相结合，形成一个虚实结合的水务系统。通过数字技术和物理技术相结合，利用传感器、互联网、云计算、大数据分析等技术手段，将实体系统的运行状态、水资源分配和利用、水环境监测等信息数字化，可以为水务领域的决策者、管理者提供全方位的信息支持。",
        },
        {
          name: "two",
          imgUrl: require("../../assets/images/zhihuijiaotong@2x.png"),
          path: "traffic",
          title: "智慧交通数字孪生解决方案",
          desc: "智慧交通数字孪生解决方案以结合现代数字技术和智能交通系统，利用传感器、计算机视觉、无线通信等技术，实时收集、处理和分析交通数据，并将其反馈到交通控制中心，从而实现对道路交通的精准控制和管理。通过数字化建模、仿真和优化等手段，实现对物理系统的监测和管理。可以应用于城市交通、高速公路、机场等领域，为交通管理提供更加精准和高效的方案。",
        },
        {
          name: "three",
          imgUrl: require("../../assets/images/gangtieyejin@2x.png"),
          path: "metallurgy",
          title: "钢铁冶金数字孪生解决方案",
          desc: "利用先进的数字孪生技术将实体世界中的物理系统建模为虚拟的数字模型，并将实时数据与该模型进行实时比对和更新，用于模拟钢铁冶金生产过程中的各种情况，并提供预测和优化建议；此外，我们还可以与现有的生产管理系统、物流管理系统等进行集成，实现更高效的生产流程。从而帮助冶炼企业提高生产效率、降低生产成本、增强竞争力和减少环境影响。",
        },
        {
          name: "four",
          imgUrl: require("../../assets/images/guangfu@2x.png"),
          path: "energy",
          title: "智慧光伏数字孪生解决方案",
          desc: "光伏新能源数字孪生解决方案是基于数字孪生技术的集成解决方案，旨在帮助光伏新能源行业提高太阳能发电效率、降低光伏电站运营成本、提升设备运行安全性和稳定性。方案通过数字孪生技术将现实世界中的光伏电站映射到虚拟世界中，构建了一个全方位的数字化运营平台，实现了对光伏电站的实时监控、预测分析、故障诊断和设备优化等功能，从而使光伏电站的运营更加智能化、高效化和可持续化。",
        },
      ];
      this.swiperList2 = [
        {
          name: "one",
          id: 1,
          imgUrl: require("../../assets/images/pingtailiangdian_1.png"),
        },
        {
          name: "two",
          id: 2,
          imgUrl: require("../../assets/images/pingtailiangdian_2.png"),
        },
        {
          name: "three",
          id: 3,
          imgUrl: require("../../assets/images/pingtailiangdian_3.png"),
        },
      ];
      return {
      };
    },
    methods: {
      tiyan() {
        router.push({
          path: "/contact",
        });
      },
  
      handleCarouselChange() { },
    },
    mounted() {
      window.scrollTo(0, 0)
      console.log('分辨率-宽---', window.screen.width)
      console.log('分辨率-宽---', document.body.clientWidth)
    },
  };
  </script>
  
  <style scoped>
  
  
  .home-top {
    position: relative;
    overflow: hidden;
  }
  
  .home-top img {
    width: 100%;
    min-width: 1200px;
  }
  
  .home-top ul {
    position: absolute;
    top: 36%;
    left: 20%;
  }
  
  .home-top ul li:nth-child(1) {
    width: 460px;
    height: 38px;
    font-size: 37px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
    line-height: 24px;
  }
  
  .home-top ul li:nth-child(2) {
    width: 460px;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #f2f2f2;
    line-height: 24px;
    opacity: 0.3;
    padding-bottom: 32px;
  }
  
  .home-top ul li:nth-child(3) {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #f2f2f2;
    line-height: 24px;
    padding-bottom: 50px;
  }
  
  .home-top ul li button {
    width: 190px;
    height: 54px;
    background: linear-gradient(90deg, #006fff 0%, #4699fa 100%);
    border-radius: 8px;
    color: white;
    font-size: 24px;
    font-family: Source Han Sans CN;
    border: none;
  }
  
  .example-content-light {
    /* border: 1px solid red; */
    /* width: 62.5%; */
    margin: 0 auto;
  }
  
  .home_banner {
    /* height: 791px; */
    height: 36.5vw;
    width: 100%;
    /* background: yellow; */
  }
  
  .luanshengpingtai {
    padding-top: 60px;
  }
  
  .light_point_container {
    background: #212429;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    padding-right: 33px;
  }
  
  .light_point_container h1 {
    font-size: 32px;
    font-weight: 500;
    color: #fefeff;
    /* width: 100%; */
    /* background: red; */
    text-align: center;
    margin-top: 60px;
    margin-bottom: -5px;
  }
  
  .light_point_container .show_content {
    display: flex;
    width: 100%;
    height: 557px;
  }
  
  .solution_container {
    background: #212429;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-bottom: 80px;
  }
  
  .solution_container .solution_title {
    /* width: 100%; */
    text-align: center;
    font-size: 32px;
    color: #fefeff;
    margin-top: 110px;
  }
  
  .solution_container .solution_swiper_box {
    width: 1200px;
    height: 538px;
    margin-top: 42px;
  }
  
  .solution_container .solution_swiper_box .carousel-indicator {
    position: relative !important;
    top: 30px !important;
  }
  </style>
  <style>
  .home_page_container .solution_swiper_box .carousel-indicator {
    position: relative !important;
    top: 25px !important;
  }
  
  .home_banner .carousel-indicator {
    position: relative !important;
    top: -50px !important;
    /* left: -550px !important; */
    /* background: red; */
    /* text-align: left; */
    display: flex;
    justify-content: left;
    margin-left: 14.1vw;
  }
  

  </style>