<template>
  <show />
</template>

<script>
import show from "./components/show.vue"
export default({
  components: {
    show
  }
})
</script>

<style lang="scss" scoped>

</style>
