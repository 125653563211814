<template>
  <div class="product_description_container">
    <p>新手指引</p>
    <p>
      云数据库、域名与虚拟主机等核心产品的使用说明文档，通过快速入门教程，实践案例，视频教程等各项操作指南，帮助新用户更快的了解产品的使用。
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.product_description_container {
  width: 100%;
  height: 258px;
  padding-top: 86px;
  padding-left: 360px;
  background-image: url("../../assets/images/chanpshuoming-banner@2x.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}
p {
  font-family: Source Han Sans CN;
}
p:nth-child(1) {
  color: #ffffff;
  font-weight: bold;
}
p:nth-child(2) {
  width: 601px;
  line-height: 24px;
  font-size: 14px;
  font-weight: 300;
  margin-top: 23px;
}
</style>