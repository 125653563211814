<template>
    <div class="mobile_introduct_container flex_center">
        <img class="mobile_in_develop_img" src="../../../../assets/images/kaifazhong.png" alt="" />
        <p class="mobile_in_develop_text">正在开发中...</p>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.mobile_introduct_container {}

.mobile_in_develop_img {
    width: 1068px;
    height: auto;
}

.mobile_in_develop_text {
    font-size: 64.08px;
    font-family: Source Han Sans CN;
    font-weight: 300;
    color: #B2B2B2;
    line-height: 106.8px;
    margin-top: 66.75px;
    /* text-align: center; */
}
</style>