<template>
    <div class="mobile_solution_container">
        <section class="mobile_solution_banner_box">
            <img src="../../../assets/images/mobile_solution_banner.png" alt="">
        </section>
        <section class="mobile_solution_collapse_box">
            <el-collapse v-model="collapseName" accordion>
                <el-collapse-item v-for="(item, index) in solutionSelectArr" :key="index" :title="item.title" :name="item.name"
                    class="mobile_parent_collapse_item">
                    <div class="mobile_solution_collapse_content_box">
                        <p class="mobile_solution_introduct_title">平台简介</p>
                        <img :src="item.introductImgUrl" alt="">
                        <p class="mobile_solution_introduct_desc">{{ item.introductText }}</p>
                        <p class="mobile_solution_introduct_title">方案架构图</p>
                        <img :src="item.jiagouImgUrl" alt="">
                        <p class="mobile_solution_introduct_title">建设内容</p>
                        <div class="mobile_solution_build_box" v-for="(it, ind) in item.buildContentArr" :key="ind">
                            <p class="mobile_build_item_title">{{ it.title }}</p>
                            <p class="mobile_build_item_desc">{{ it.desc }}</p>
                        </div>
                    </div>
                </el-collapse-item>
            </el-collapse>

        </section>
    </div>
</template>

<script>
export default {
    name: '',
    data() {
        this.solutionSelectArr = [
            {
                title: "智慧水务",
                name: 'water',
                introductImgUrl: require("../../../assets/images/zhihuishuiwu.png"),
                introductText:
                    "智慧水务数字孪生解决方案是将水资源管理、水环境保护、供水、排水等水务领域的实体系统与数字化系统相结合，形成一个虚实结合的水务系统。通过数字技术和物理技术相结合，利用传感器、互联网、云计算、大数据分析等技术手段，将实体系统的运行状态、水资源分配和利用、水环境监测等信息数字化，形成一个与实体系统相对应的虚拟系统，从而实现水务系统的全面数字化，包括数据采集、数据存储、数据分析和决策支持等功能，可以为水务领域的决策者、管理者提供全方位的信息支持。",
                jiagouImgUrl: require("../../../assets/images/zhuihuishuiwu_jiagou.png"),
                buildContentArr: [
                    {
                        title: "智能监测与分析",
                        desc: "利用物联网、传感器、大数据、云计算等技术，建立水务系统的数字孪生模型，实现对水质、水量、水压等数据的实时监测和分析，以便快速发现异常情况并采取措施。",
                    },
                    {
                        title: "智能配水调度",
                        desc: "基于数字孪生模型，利用人工智能和优化算法，对水务系统进行智能配水调度，以提高供水效率和减少能耗。同时，还可以对水库、水闸等设施进行实时监测和调控，以保障水务系统的稳定运行。",
                    },
                    {
                        title: "智慧水务管网",
                        desc: "通过数字孪生模型，实现对水务管网的实时监测和管理，包括管道的压力、流量、温度等参数。通过数据分析和预测，可以及时发现管网中的漏水、破损等问题，并采取相应的维修措施，以减少水资源的浪费。",
                    },
                    {
                        title: "智慧供水安全",
                        desc: "对供水系统进行全方位的监测和预测，及时发现水质问题，包括微生物、化学物质等。同时，通过数字孪生技术，可以及时响应突发事件，保障供水系统的安全运行。",
                    },
                    {
                        title: "智慧水利",
                        desc: "对水库、水闸等水利设施进行实时监测和管理，掌握水位、水流等变化情况，预测水文情况，并提供灾害预警。通过数字孪生技术，可以提高水利设施的管理效率和水资源的利用效率，保障水利工程的安全稳定运行。",
                    },
                    {
                        title: "绿色水务管理",
                        desc: "对水资源的使用情况进行全面监测和分析，发现水资源的浪费和滥用情况，并提出相应的治理措施，以实现绿色水务管理。",
                    },
                    {
                        title: "智能化排污管理",
                        desc: "通过数字孪生技术，可以实时监控污水排放情况，识别污染源和污染物浓度，及时采取措施降低污染，提高环境质量和生态效益。通过监测和控制污水排放，实现污水的治理和管理。",
                    },
                ],
            },
            {
                title: "智慧交通",
                name: 'traffic',
                introductImgUrl: require("../../../assets/images/zhihuijiaotong.png"),
                introductText: "智慧交通数字孪生解决方案以结合现代数字技术和智能交通系统，利用传感器、计算机视觉、无线通信等技术，实时收集、处理和分析交通数据，并将其反馈到交通控制中心，从而实现对道路交通的精准控制和管理。通过数字化建模、仿真和优化等手段，实现对物理系统的监测和管理。并将实际道路和车流情况数字化，建立起交通网络的数字化副本，通过实时数据的更新和优化，最大限度地提高交通运行效率和安全性。可以应用于城市交通、高速公路、机场等领域，为交通管理提供更加精准和高效的方案。",
                jiagouImgUrl: require("../../../assets/images/zhihuijiaotong_jiagou.png"),
                buildContentArr: [
                    {
                        title: "实时交通监控和预测",
                        desc: "利用数字孪生技术对交通流量、道路状况、车辆状态等数据进行实时监控和分析，可以预测未来交通情况，包括拥堵、事故等，以便采取及时的调整和应对措施，提高交通运输效率和安全性。",
                    },
                    {
                        title: "智能化交通信号灯",
                        desc: "利用数字孪生技术将交通信号灯和交通监控系统相连接，实现实时调整交通信号灯的时序和灯色，以最大化地提高交通流量和减少拥堵。",
                    },
                    {
                        title: "智能公共交通系统",
                        desc: "通过数字孪生技术对公共交通系统进行实时监测和管理，包括公交车辆的位置、到站时间、客流量等信息。通过数字孪生技术，可以实现公共交通的优化调度和提高服务质量。",
                    },
                    {
                        title: "智能驾驶辅助",
                        desc: "将车辆和交通系统相连，实现实时交通数据的传输和分析，以提供实时的交通状况、路况等信息给驾驶员，从而实现智能驾驶辅助，提高驾驶安全性。",
                    },
                    {
                        title: "道路安全监测和预警",
                        desc: "对道路状况和交通事故进行实时监测和分析，包括路面状况、天气情况、车速等信息。通过数字孪生技术，可以实现对危险路段和交通事故的预警和应急响应，提高道路交通的安全性。",
                    },
                    {
                        title: "智能停车管理",
                        desc: "对城市停车场、路边停车位等进行实时监控和管理，包括空位数量、停车时间等信息。通过数字孪生技术，可以实现智能化停车引导、停车位预约等功能，提高城市停车管理的效率和服务质量。",
                    },
                    {
                        title: "交通网络优化",
                        desc: "将交通系统中的各个节点（如路口、路段等）进行模拟和优化，以最大化地提高交通流量和减少拥堵。通过数字孪生技术，可以实现交通网络的动态调整和优化，提高交通运输的效率和质量。",
                    },
                    {
                        title: "智能高速公路管理",
                        desc: "对高速公路进行实时监控和管理，包括车辆通行速度、交通事故、路面状况等信息。通过数字孪生技术，可以实现智能化的高速公路管理，提高高速公路的安全性和运营效率。",
                    },
                    {
                        title: "数据分析与预测",
                        desc: "将交通系统中的各种数据进行收集、存储和分析，包括交通流量、车速、车辆位置等信息。通过数字孪生技术，可以实现数据的可视化展示、趋势预测和数据挖掘，提高交通管理的决策精度和效率。",
                    },
                    {
                        title: "环保交通管理",
                        desc: "帮助交通系统实现更加环保的管理方式，如优化交通流量、提高公共交通服务、减少私家车使用等，以减少交通带来的环境污染和碳排放，推动城市可持续发展。",
                    }
                ],
            },
            {
                title: "钢铁冶金",
                name: "metallurgy",
                introductImgUrl: require("../../../assets/images/gangtieyejin.png"),
                introductText: "利用先进的数字孪生技术将实体世界中的物理系统建模为虚拟的数字模型，并将实时数据与该模型进行实时比对和更新，用于模拟钢铁冶金生产过程中的各种情况，并提供预测和优化建议；此外，我们还可以与现有的生产管理系统、物流管理系统等进行集成，实现更高效的生产流程，包括生产计划优化、生产工艺优化、质量控制、设备维护和安全监控等方面。可以帮助企业实现精准的原料配比、减少能源消耗、提高产品质量、预测设备故障和提高安全性能等。从而帮助冶炼企业提高生产效率、降低生产成本、增强竞争力和减少环境影响。",
                jiagouImgUrl: require("../../../assets/images/gangtieyejin_jiagou (2).png"),
                buildContentArr: [
                    {
                        title: "生产过程监控",
                        desc: "通过数字孪生技术，实时监控钢铁冶金企业的生产过程，及时发现问题并采取措施，从而提高生产效率和质量。",
                    },
                    {
                        title: "模拟仿真",
                        desc: "利用数字孪生技术建立钢铁冶金行业的数字化仿真模型，从而预测生产过程中的各种可能情况，避免生产中出现的问题，提高效率和降低成本。",
                    },
                    {
                        title: "优化生产计划",
                        desc: "帮助钢铁冶金企业实现生产计划的实时优化，减少生产线的闲置和浪费，提高生产效率。",
                    },
                    {
                        title: "能耗管理",
                        desc: "通过对钢铁冶金企业生产过程的数字化建模，实现能源消耗的实时监测和管理，降低能耗成本。",
                    },
                    {
                        title: "预防维护",
                        desc: "通过对钢铁冶金设备进行数字化建模和监测，预测设备的运行状态和故障，从而实现预防性维护，降低设备故障率和维护成本。",
                    },
                    {
                        title: "员工培训",
                        desc: "通过数字孪生技术，钢铁冶金企业可以建立虚拟的生产环境，为员工提供实践培训，提高员工的技能水平。",
                    },
                    {
                        title: "数据分析",
                        desc: "通过数字孪生技术收集和分析大量生产数据，为钢铁冶金企业提供决策支持，提高决策效率和准确性。",
                    },
                ],
            },
            {
                title: "仓储物流",
                name: 'store',
                introductImgUrl: require("../../../assets/images/cangchuwuliu.png"),
                introductText: "仓储物流数字孪生解决方案是基于数字技术和物联网技术实现的智能化仓储管理系统。通过数字孪生技术，将实体仓库的物理结构、设备、库存等信息数字化，构建一个虚拟的数字孪生模型。在此基础上，可以进行数据分析、模拟仿真、优化调度等操作，实现仓储物流的数字化转型。",
                jiagouImgUrl: require('../../../assets/images/cangchuwuliu_jiagou.png'),
                buildContentArr: [
                    {
                        title: "库存管理",
                        desc: "利用数字孪生技术实时监测仓库库存情况，并与订单系统实现数据对接。利用物联网技术，可实现RFID、传感器等设备的联网，对库存进行自动化管理，提高库存管理的效率和准确度。",
                    },
                    {
                        title: "运输管理",
                        desc: "构建虚拟的运输网络，实现路线优化、运输调度等功能。同时，可以利用物联网技术，对运输车辆、货物等进行实时监控和追踪，确保运输安全和及时性。",
                    },
                    {
                        title: "设备管理",
                        desc: "对仓库设备进行虚拟化建模，实现设备的状态监测和故障预测。同时，利用物联网技术，对设备进行实时监控，可实现设备自动化控制和维护管理。",
                    },
                    {
                        title: "数据分析",
                        desc: "对仓库运营数据进行实时监测和分析。可以通过数据挖掘和人工智能技术，发现仓库运营的问题和机会，提出相应的改进措施。",
                    },
                    {
                        title: "人员管理",
                        desc: "对仓库人员进行实时监测和管理，实现人员考勤、安全管理等功能。同时，可利用物联网技术，对人员进行实时定位和监控，确保人员的安全和工作效率。",
                    },
                    {
                        title: "订单管理",
                        desc: "对订单信息进行实时监测和管理，实现订单处理、发货、退货等功能。同时，可利用物联网技术，对订单进行实时追踪和监控，提高订单处理的效率和准确度。",
                    },
                ],
            },
            {
                title: "智慧工厂",
                name: 'factory',
                introductImgUrl: require("../../../assets/images/zhihuigongchang.png"),
                introductText: "利用数字孪生技术将实体工厂的物理过程、操作和数据等信息完全复制到虚拟环境中，模拟实际工厂的运行情况，通过数字孪生模型进行优化、分析和管理，通过收集实时数据并进行数据分析和建模，帮助制造商提高生产效率，减少故障率和成本，并提高决策效率和创新速度，从而增强竞争力和市场地位。",
                jiagouImgUrl: require('../../../assets/images/zhihuigongchang_jiagou.png'),
                buildContentArr: [
                    {
                        title: "智能制造",
                        desc: "通过数字孪生模型，可以实现生产过程的全面监测和优化，从而提高生产效率和质量，降低成本。",
                    },
                    {
                        title: "设备监测",
                        desc: "数字孪生技术可以为工厂提供实时的设备监测和维护。通过数字孪生模型实现设备的远程监测和故障预测，从而减少设备损坏和停机时间。",
                    },
                    {
                        title: "物流管理",
                        desc: "为工厂提供全方位的物流管理服务。通过数字孪生模型实现物流流程的可视化管理和优化，从而降低物流成本和提高物流效率。",
                    },
                    {
                        title: "质量控制",
                        desc: "通过数字孪生模型实现产品质量的全面监测和追溯，从而提高产品质量和客户满意度。",
                    },
                    {
                        title: "环境监测",
                        desc: "通过数字孪生模型，可以实现环境参数的实时监测和分析，从而减少环境污染和提高生产环境的舒适度。",
                    },
                    {
                        title: "安全管理",
                        desc: "数字孪生技术可以为工厂提供全面的安全管理解决方案。通过数字孪生模型，可以实现安全风险的全面评估和预测，从而减少安全事故的发生和保障员工的安全。",
                    },
                    {
                        title: "远程协作",
                        desc: "数字孪生技术可以为工厂提供远程协作解决方案。通过数字孪生模型，可以实现跨地域的协同合作和信息共享，从而提高工作效率和生产能力。",
                    },
                ],
            },
            {
                title: "智慧政务",
                name: 'duty',
                introductImgUrl: require("../../../assets/images/zhihuizhengwu.png"),
                introductText: "智慧政务数字孪生解决方案是利用数字孪生技术模拟政府各部门的运行情况，收集实时数据并进行数据分析和建模，优化政府部门的资源配置和管理，提高服务效率和质量，降低成本和风险。还可以模拟政务服务流程，如市民服务、环保监管、公共安全等，提高服务质量和效率，降低服务成本。此外，它还可以模拟政府部门的资源利用情况，优化资源配置和使用，提高资源效率，减少浪费。同时，数字孪生系统可以提供实时数据和可视化报告，帮助政府部门做出更明智的决策，降低风险，提高效率和质量。",
                jiagouImgUrl: require('../../../assets/images/zhihuizhengwu_jiagou.png'),
                buildContentArr: [
                    {
                        title: "城市规划与设计",
                        desc: "政府可以利用数字孪生技术来进行城市规划和设计，通过数字模型模拟城市的建设、道路规划、绿化规划等，以优化城市空间布局、提高城市建设质量。",
                    },
                    {
                        title: "基础设施管理",
                        desc: "对城市基础设施进行全面、实时的管理，包括道路、桥梁、公共交通、水、电、气、通信等基础设施，以确保城市基础设施的运行效率和安全性。",
                    },
                    {
                        title: "城市运营管理",
                        desc: "帮助政府进行城市运营管理，包括城市交通管理、环保管理、城市治安管理等方面，以提高城市管理的效率和水平。",
                    },
                    {
                        title: "紧急事件管理",
                        desc: "在突发事件发生时，进行快速、精准的响应，包括自然灾害、重大事故等，以保障公众安全和减少损失。",
                    },
                    {
                        title: "公共服务优化",
                        desc: "提供更加智能、个性化的公共服务，包括医疗、教育、社保、住房等方面，以提高公众的生活质量和满意度。",
                    },
                    {
                        title: "行政决策支持",
                        desc: "数字孪生技术可以帮助政府进行科学决策，包括预测模拟、风险评估、政策制定等方面，以提高政府的决策效率和准确性。",
                    },
                    {
                        title: "智慧城市建设",
                        desc: "支持智慧城市建设。政府可以利用数字孪生平台来实现城市智能化，包括智能交通、智能环保、智能治安等方面的建设。",
                    },
                    {
                        title: "全生命周期管理",
                        desc: "支持全生命周期管理，包括设计、建造、运营和维护等方面。政府可以利用数字孪生技术来管理城市基础设施、公共设施等事务的全生命周期。",
                    },
                    {
                        title: '大数据应用',
                        desc: '更好地应用大数据。政府可以利用数字孪生平台来收集和分析大量数据，以实现更好的城市管理和公共服务。'
                    },
                    {
                        title: '开放性和互联性',
                        desc: '数字孪生平台应具有开放性和互联性。政府可以将数字孪生平台与其他政务信息系统和服务相结合，以实现更好的信息共享和服务协同。'
                    }
                ],
            },
            {
                title: "智慧园区",
                name: 'yuanqu',
                introductImgUrl: require("../../../assets/images/zhihuiyuanqu.png"),
                introductText: "智慧园区数字孪生解决方案是基于数字孪生技术的创新性解决方案，旨在帮助园区提高运营效率和质量，降低成本和风险，加速数字化转型和智慧化升级。方案将数字孪生技术与物联网、云计算、人工智能等技术相结合，构建了一个全方位的数字化运营平台，实现了对园区各个方面的实时监控、数据分析、决策支持和资源优化等功能，从而使园区的运营更加智能化、高效化和可持续化。",
                jiagouImgUrl: require('../../../assets/images/zhihuiyuanqu_jiagou.png'),
                buildContentArr: [
                    {
                        title: "园区规划和设计",
                        desc: "利用数字孪生技术来进行园区规划和设计，通过数字模型模拟园区的建设、道路规划、绿化规划等，以优化园区空间布局、提高园区建设质量。",
                    },
                    {
                        title: "园区运营管理",
                        desc: "帮助园区管理者进行全面、实时的运营管理，包括资源利用情况、设备运行状态、环境监测等方面，以提高园区的运营效率和安全性。",
                    },
                    {
                        title: "能源管理",
                        desc: "帮助园区管理者进行能源管理，包括能源使用情况、能源消耗情况、能源浪费情况等方面，以提高能源的利用效率和降低能源的消耗。",
                    },
                    {
                        title: "园区交通管理",
                        desc: "帮助园区管理者进行园区的智慧交通管理，包括交通状况、车流量、道路拥堵情况等方面，以提高园区的交通流畅度和安全性。",
                    },
                    {
                        title: "安全管理",
                        desc: "帮助园区管理者进行安全管理，包括视频监控、安全巡查、火灾报警等方面，以保障园区的安全和稳定。",
                    },
                    {
                        title: "生产效率提升",
                        desc: "数字孪生技术可以帮助园区进行生产效率提升，包括生产线的优化、生产过程的监控等方面，以提高园区的生产效率和降低生产成本。",
                    },
                    {
                        title: '人员管理',
                        desc: '帮助园区管理者进行人员管理，包括员工出勤情况、工作安排、人员轨迹监控等方面，以提高人员管理的效率和准确性。'
                    }
                ],
            },
            {
                title: "光伏能源",
                name: 'energy',
                introductImgUrl: require("../../../assets/images/guangfunengyuan.png"),
                introductText: "光伏新能源数字孪生解决方案是基于数字孪生技术的集成解决方案，旨在帮助光伏新能源行业提高太阳能发电效率、降低光伏电站运营成本、提升设备运行安全性和稳定性。方案通过数字孪生技术将现实世界中的光伏电站映射到虚拟世界中，构建了一个全方位的数字化运营平台，实现了对光伏电站的实时监控、预测分析、故障诊断和设备优化等功能，从而使光伏电站的运营更加智能化、高效化和可持续化。",
                jiagouImgUrl: require('../../../assets/images/guangfunengyuan_jiagou.png'),
                buildContentArr: [
                    {
                        title: "光伏电站设计和规划",
                        desc: "帮助光伏电站设计和规划人员实现更高质量的光伏电站设计和规划。通过数字化建模和仿真，数字孪生技术可以提供更加准确的数据支持，帮助设计和规划人员制定更加合理和高效的光伏电站设计方案。",
                    },
                    {
                        title: "光伏电站运维管理",
                        desc: "实现对光伏电站各项运营活动的数字化监测和管理。还可以提供数据支持，帮助光伏电站管理者做出更加明智的决策。",
                    },
                    {
                        title: "光伏电站运行数据分析",
                        desc: "对光伏电站运行数据进行深度分析。数字孪生技术还可以提供数据支持，帮助光伏电站管理者优化光伏电站运营策略。",
                    },
                    {
                        title: "光伏发电量预测和优化",
                        desc: "预测和优化光伏发电量。例如，可以通过数字孪生技术实现光伏发电量的实时监控和预测，以帮助光伏电站管理者制定更加合理的光伏发电量规划和优化方案。数字孪生技术还可以提供数据支持，帮助光伏电站管理者提高光伏发电效率。",
                    },
                    {
                        title: "光伏电站安全管理",
                        desc: "通过数字孪生技术实现对光伏电站安全状态的实时监控和管理，以提高光伏电站的安全管理。",
                    },
                    {
                        title: "光伏电站质量监控",
                        desc: "实现光伏电站质量监控的数字化监测和管理。通过数字孪生技术实现对光伏电站各项设备和材料的质量监控，以保障光伏电站的稳定运营。",
                    },
                    {
                        title: "光伏电站环境保护",
                        desc: "实现对光伏电站环境的数字化监测和保护，以及光伏电站周边环境的实时监控和管理，以保护光伏电站周边环境和生态系统。",
                    },
                    {
                        title: "光伏电站供应链管理",
                        desc: "实现供应链的数字化监测和管理。通过数字孪生技术实现光伏电站各项物资和设备的供应链管理，以保障光伏电站的稳定运营。",
                    },
                    {
                        title: "光伏电站故障排除",
                        desc: "快速排除可能出现的故障和问题。通过数字孪生技术实现光伏电站内设备和资源的实时监控和诊断，以快速排除光伏电站可能出现的故障和问题，提高光伏电站的稳定性和可靠性。",
                    },
                ],
            },
            {
                title: "智慧校园",
                name: 'school',
                introductImgUrl: require("../../../assets/images/zhihuixiaoyuan.png"),
                introductText: "以数字化、智能化和精细化管理为目标，建立完善的数据共享和安全保护机制，以优化教育资源、提高教学效果、精细化管理学生为目标，实现校园运营的高效、智能和可持续发展。帮助学校更加高效、科学、智能地管理教育事务，提高教育质量。",
                jiagouImgUrl: require('../../../assets/images/zhihuixiaoyuan_jiagou.png'),
                buildContentArr: [
                    {
                        title: "建立数字孪生模型",
                        desc: "通过数字化技术对校园进行全面、深入地建模，包括建筑物、设施设备、学生、教师等各类资源。对数据进行分析和处理，实现对校园运营状态的实时监测、预测和优化。",
                    },
                    {
                        title: "数据共享平台",
                        desc: "建立校园内部数据共享平台，实现不同部门之间的信息共享和协同工作，提高校园运营效率和管理水平。同时，通过数字化技术对校园外部资源进行整合和开放，打造数字化校园生态系统，促进校园与社会的融合和交流。",
                    },
                    {
                        title: "智能化设施管理",
                        desc: "通过数字化技术对校园设施设备进行实时监测和分析，提高设施设备的使用效率和维护质量。同时，通过人工智能技术对设施维修和保养进行优化和自动化，减少人力和物力资源的浪费。",
                    },
                    {
                        title: "智能化学习支持",
                        desc: "通过人工智能技术对学生学习行为进行分析和建模，为学生提供个性化的学习支持和推荐。同时，通过数字化技术对课程资源进行整合和优化，提高教学效率和教学质量。",
                    },

                ],
            },
            {
                title: "智慧医疗",
                name: 'yiliao',
                introductImgUrl: require("../../../assets/images/zhihuiyiliao.png"),
                introductText: "通过数字孪生技术和智能化管理，实现医疗设备、医疗资源、医疗数据等各类信息的集成和优化，从而提高医疗服务质量、效率和精准度。方案利用数字孪生技术建立医疗设备、医疗资源等数字孪生模型，对医疗服务流程进行全面管理和优化，实现从疾病预防到诊疗、康复全流程的精准治疗和管理。业务应用包括：智慧医院、智慧诊疗、智慧养老等。",
                jiagouImgUrl: require('../../../assets/images/zhihuiyiliao_jiagou.png'),
                buildContentArr: [
                    {
                        title: "医院数字孪生平台搭建",
                        desc: "构建医院数字孪生平台，包括病历管理、医疗设备管理、医护人员管理、药品管理、病房管理等各方面，实现医院信息化管理和智能化决策支持。",
                    },
                    {
                        title: "智慧医疗大数据应用",
                        desc: "通过数字孪生技术实现医疗大数据的采集、存储、分析和挖掘，以及医疗智能决策支持，提高医疗质量和效率。",
                    },
                    {
                        title: "医疗设备数字孪生",
                        desc: "将医疗设备数字化建模，实现设备的运行监测、预测性维护，提高设备的可靠性和使用寿命。",
                    },
                    {
                        title: "个人医疗数字孪生",
                        desc: "将病人信息数字化建模，实现对病人的实时监测和数据采集，提高诊疗精度和效率，同时为病人提供个性化的医疗服务。",
                    },
                    {
                        title: "医疗资源智能化管理",
                        desc: "将药品等医疗资源进行信息数字化建模，实现对药品等医疗资源的实时监测和管理，提高医疗资源的安全性和有效性。",
                    },
                    {
                        title: "智能医疗辅助诊断",
                        desc: "利用数字孪生技术结合人工智能技术，实现医疗辅助诊断，提高医疗精度和效率。",
                    },
                    {
                        title: '医疗资源数字化管理',
                        desc: '通过数字孪生技术实现医疗资源的数字化管理，包括人力资源、物资资源、财务资源等方面，提高医疗资源的利用效率和管理效果。'
                    },
                    {
                        title: '智慧医疗服务',
                        desc: '通过数字孪生技术和互联网技术结合，实现互联网医疗的数字化建设，包括在线问诊、远程医疗、医疗咨询等方面，提高医疗服务的普及和便捷性。'
                    }
                ],
            },
        ];
        return {
            collapseName: ''
        }
    },
    mounted() {
        const type = this.$route.query.type
        console.log('type---', type)
        if (type) {
            this.collapseName = type
        }
        window.scrollTo(0, 0)
    }
}
</script>

<style lang="scss" scoped>
.mobile_solution_container {
    .mobile_solution_banner_box {
        img {
            width: 1920px;
        }
    }
}
</style>
<style lang="scss">
.mobile_solution_container .el-collapse {
    border: none;
}

.mobile_solution_container .el-collapse-item__header {
    height: 208.26px;
    background: #212429;
    font-size: 74.76px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    padding-left: 98.79px;
    border-bottom: 2.67px solid #353941;
    border-top: 2.67px solid #353941;

}

.mobile_solution_container .mobile_parent_collapse_item .el-collapse-item__header {
    height: 208.26px;
    background: #212429;
    font-size: 74.76px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    padding-left: 98.79px !important;
    padding-right: 133.5px;
    border-bottom: 2.67px solid #353941;
    border-top: 2.67px solid #353941;

}

.mobile_solution_container .el-collapse-item__wrap {
    background: #212429;
    border: none;
}

.mobile_solution_container .el-collapse-item__wrap .el-collapse-item__content {
    /* background: #212429; */
}

.mobile_solution_container .mobile_product_description_collapse_box .el-collapse-item__wrap .el-collapse-item__content p {
    height: 133.5px;
    color: #B2B2B2;
    padding-left: 248.31px;
    font-size: 64.08px;
    margin-top: 53.4px;
}

.mobile_solution_collapse_box {
    .mobile_solution_collapse_content_box {
        padding: 53.4px 106.8px;
        .mobile_solution_introduct_title {
            font-size: 66.75px;
            font-family: Source Han Sans CN;
            font-weight: 300;
            color: #FFFFFF;
            margin-top: 26.7px;
            margin-bottom: 26.7px;
        }

        img {
            width: 1735.5px;
            height: auto;
            text-align: center;
        }

        .mobile_solution_introduct_desc {
            font-size: 64.08px;
            font-family: Source Han Sans CN;
            font-weight: 300;
            color: #B2B2B2;
            line-height: 106.8px;
            margin-top: 66.75px;
            text-indent: 2em;
        }

        .mobile_solution_build_box {
            .mobile_build_item_title {
                color: #2070e5;
                font-size: 64.08px;
            }

            .mobile_build_item_desc {
                font-size: 53.4px;
                font-family: Source Han Sans CN;
                font-weight: 300;
                color: #B2B2B2;
                line-height: 106.8px;
                margin-top: 40.05px;
                text-indent: 2em;
            }
        }

    }
}
</style>