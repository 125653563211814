<template>
  <div class="application_case_banner"></div>
</template>

<script>
export default {

}
</script>

<style scoped>
.application_case_banner {
    width: 100%;
    height: 640px;
    background-image: url('../../assets/images/yingyonganli-banner.png');
    background-repeat: no-repeat;
    background-size: cover;
}
</style>