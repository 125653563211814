<template>
  <div class="product_frame_introduction_container">
    <p class="title1">ForceCon-DTwin产品框架介绍</p>
    <p class="title2">架构组成</p>
    <p class="desc_text">
      数字孪生PaaS平台是力控沉淀多年、实现完全的云原生重构平台，是基于新一代的微服务、容器化技术进行系统架构、运用前后端分离的开发方式、研发企业级基础技术组件，打造的高安全、高性能的孪生PaaS平台。ForceCon-Dtwin提供“三个中台”+“一个平台”的组合架构，支撑自研IDC的高并发和稳定性。
    </p>
    <div class="img_box_one">
      <img src="../../assets/images/pingtaijiagou_1.png" alt="" />
    </div>
    <p class="desc_text_2">
      从基础设施层面着手，从底层搭建了基于云原生技术的技术平台，提供云原生的核心驱动力，通过云原生的应用，能够更好地解决微服务架构带来的配置、部署、运维、服务管理和监控等问题，降低研发、部署复杂度。Forcecon-Dtwin技术平台包含云原生技术支撑平台、微服务治理平台、AIot物联平台，深度践行DevOps理念，集容器云、DevOps、服务治理、监控运维、测试工具为一体，向上支撑各领域及行业云服务，向下适配平台自建IDC，提供全面的混合云架构解决方法。
    </p>
    <div class="img_box_two"></div>
    <p class="title2">功能框架</p>
    <p class="desc_text_2">
      力控数字孪生平台-ForceCon-DTwin实行BS/CS双框架并行开发理念，以及业界主流的网络协议、硬件接口和数据库，将各个子系统功能逻辑合理划分，耦合度低，保障良好的扩展性和开放性。具备三维模型管理、中间数据库、数据接口、H5多形式数据图表展示、设备管理、权限管理、监控可视化、告警可视化、运行可视化、操作可视化、门禁可视化、应用配置、B/S信息接入、终端协同、工艺规划配置、语音通讯功能、定位及跳转、漫游巡检、虚实联动等一系列完善的功能模块，可以满足各大企业的私人定制需求及后续。
    </p>
    <div class="img_box_three"></div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.product_frame_introduction_container {
  padding: 0 104px;
}
.product_frame_introduction_container .title1 {
  width: 100%;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
}
.product_frame_introduction_container .title2 {
  width: 242px;
  height: 36px;
  line-height: 36px;
  color: #ffffff;
  font-size: 20px;
  padding-left: 18px;
  font-weight: 500;
  background-image: url("../../assets/images/biaoti@2x@2x.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-top: 59px;
}
.product_frame_introduction_container .desc_text {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  line-height: 24px;
  /* height: 38px; */
  margin-top: 42px;
}
.product_frame_introduction_container .img_box_one {
  width: 1100px;
  height: 642px;
  margin-top: 32px;
  overflow-y: auto;
}
.product_frame_introduction_container .img_box_one img {
  width: 100%;
  /* height: 100%; */
}
.product_frame_introduction_container .desc_text_2 {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  line-height: 24px;
  /* height: 63px; */
  margin-top: 41px;
}
.product_frame_introduction_container .img_box_two {
  width: 1110px;
  height: 267px;
  background-image: url("../../assets/images/kuangjiajieshao_2.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-top: 42px;
}
.product_frame_introduction_container .img_box_three {
  width: 1100px;
  height: 808px;
  background-image: url("../../assets/images/kuangjiajieshao_3.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-top: 42px;
}
</style>