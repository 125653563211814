<template>
    <div class="mobile_appliaction_case_container">
        <section class="mobile_appliaction_case_banner">
            <img src="../../../assets/images/mobile_application_case_banner.png" alt="">
        </section>
        <section class="mobile_application_case_swiper_box">
            <p class="mobile_application_case_swiper_title">应用案例</p>
            <div class="mobile_application_case_swiper_item" v-for="(item, index) in list" :key="index">
                <template v-if="currentShow === index + 1">
                    <img :src="item.imgUrl" alt="">
                    <div class="mobile_application_case_swiper_item_desc_box">
                        <p class="mobile_application_case_swiper_item_title">{{ item.imgDescTitle }}</p>
                        <p class="mobile_application_case_swiper_item_desc">{{ item.imgDescContent }}</p>
                        <p class="mobile_more_img_box">
                            <img class="mobile_more_img" src="../../../assets/images/mobile/mobile_more.png" alt=""
                                @click="viewMoreLightPoint(index)">
                        </p>
                    </div>
                </template>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'mobileAppliactionCase',
    props: {
        list: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            currentShow: 1
        }
    },
    methods: {
        viewMoreLightPoint(index) {
            this.currentShow++
            if (this.currentShow === 4) {
                this.currentShow = 1
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.mobile_appliaction_case_container {
    .mobile_appliaction_case_banner {
        position: relative;
        top: 13.35px;
        img {
            width: 1920px;
        }
    }

    .mobile_application_case_swiper_box {
        background: #212429;
        padding: 157.53px 0 2.67px 0;

        .mobile_application_case_swiper_title {
            font-size: 85.44px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #FFFFFF;
            text-align: center;
        }

        .mobile_application_case_swiper_desc {
            font-size: 64.08px;
            font-family: Source Han Sans CN;
            font-weight: 300;
            color: #B3B3B3;
            line-height: 106.8px;
            text-align: center;
            padding: 0 128.16px;
            margin-top: 106.8px;
        }

        .mobile_application_case_swiper_item {
            text-align: center;
            margin-top: 125.49px;
            img {
                width: 1810.26px;
            }

            .mobile_application_case_swiper_item_desc_box {
                padding: 122.82px 125.49px;

                .mobile_application_case_swiper_item_title {
                    font-size: 85.44px;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    color: #FFFFFF;
                    margin-top: 122.82px;
                }

                .mobile_application_case_swiper_item_desc {
                    font-size: 64.08px;
                    line-height: 106.8px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #FFFFFF;
                    margin-top: 72.09px;
                    text-indent: 2em;
                }
            }

        }
    }
}
</style>