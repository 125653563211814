<template>
  <div class="quick_start_container">
    <p class="title">5分钟快速入门</p>
    <p>5分钟解决新手入门等基础问题，可快速完成产品配置操作！</p>
    <section class="quick_start_desc_box">
      <div class="desc_item" v-for="(item, index) in introductTextArr" :key="index"
        :style="{ backgroundImage: `url(${bgImgUrl(index)})` }" @click="toDetail(item.path)">
        <p class="title">{{ item.title }}</p>
        <p class="content">{{ item.desc }}</p>
      </div>
      <!-- <a
        class="desc_item"
        v-for="(item, index) in introductTextArr"
        :key="index"
        :style="{backgroundImage: `url(${bgImgUrl(index)})`}"
        :href="'product-description/' + item.path"
        target="_blank"
      >
        <p class="title">{{ item.title }}</p>
        <p class="content">{{ item.desc }}</p>
  </a> -->
    </section>
  </div>
</template>

<script>
export default {
  name: "quickStart",
  data() {
    this.introductTextArr = [
      {
        title: "产品框架介绍",
        desc: "ForceCon-DTwin 平台整体框架介绍，对平台的主要模块和功能快速认识",
        path: "product-frame-introduction",
      },
      {
        title: "核心功能",
        desc: "ForceCon-DTwin 平台的核心功能，包含数据连接、适用行业、工艺规划等方面的核心功能认识",
        path: "core-function",
      },
      {
        title: "3D编辑器操作说明",
        desc: "ForceCon-DTwin场景编辑的操作功能介绍，包含模型、材质等效果应用",
        path: "3d-explain",
      },
      {
        title: "UI编辑器操作说明",
        desc: "UI面板的功能及操作介绍；包含图表的应用、配置以及交互事件添加等",
        path: "ui-explain",
      },
      {
        title: "移动端操作说明",
        desc: "APP的项目新建、及内容搭建、组合、属性配置、模板保存等方面的功能介绍",
        path: "app-explain",
      },
      {
        title: "数据接入标准",
        desc: "包含数据源、不同数据集类型的添加和管理，以及到场景中数据引用介绍",
        path: "data-access-standard",
      },
    ];
    return {}
  },
  methods: {
    toDetail(path) {
      localStorage.setItem('productDescriptionUrl', path)
      // window.open(window.location.origin + "/product-description/" + path, "_blank");
      this.$router.push({
        path: '/product-description/' + path
      })
    },
    bgImgUrl(index) {
      return require(`../../assets/images/shuoming-${index + 1}@2x.png`)
    },
    linkUrl(url) {
      const origin = window.location.origin
      return origin + '/product-description/' + url
    }
  }

};
</script>

<style scoped>
.quick_start_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 50px;
  background: #212429;
  padding-bottom: 40px;
}

.quick_start_container>p {
  font-family: Source Han Sans CN;
}

.quick_start_container>p:nth-child(1) {
  color: #ffffff;
  font-size: 32px;
  font-weight: 500;
}

.quick_start_container>p:nth-child(2) {
  color: #b3b3b3;
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 42px;
  font-weight: 300;
}

.quick_start_container .quick_start_desc_box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 360px;
}

.quick_start_container .quick_start_desc_box .desc_item {
  width: 380px;
  height: 180px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 4px;
  /* background: linear-gradient(67deg,
      rgba(127, 158, 220, 0.1) 0%,
      rgba(46, 83, 182, 0.1) 100%); */
  /* opacity: 0.1; */
  padding: 29px 46px 68px 30px;
  margin-bottom: 28px;
  position: relative;
  cursor: pointer;

  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
}

.quick_start_container .quick_start_desc_box .desc_item:hover {
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.quick_start_container .quick_start_desc_box .desc_item .index {
  position: absolute;
  top: -20px;
  left: 0;
  font-size: 100px;
  font-family: D-DIN;
  font-weight: bold;
  color: #ffffff;
  /* line-height: 286px; */
  opacity: 0.2;

  background: linear-gradient(0deg, #1670d4 0%, #bed4e9 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.quick_start_container .quick_start_desc_box .desc_item .title {
  color: #ffffff !important;
  opacity: 1;
  font-size: 18px;
  font-weight: 500;
  font-family: Source Han Sans CN;
  position: absolute;
  z-index: 4;
}

.quick_start_container .quick_start_desc_box .desc_item .content {
  width: 304px;
  /* height: 38px; */
  line-height: 24px;
  margin-top: 40px;
  color: #ffffff;
  opacity: 0.7;
  z-index: 4;
  font-size: 14px;
}
</style>