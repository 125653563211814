<template>
  <mobileInDevelop />
</template>

<script>
import mobileInDevelop from './in-develop-desc.vue'
export default {
    components: {
        mobileInDevelop
    }
}
</script>

<style scoped>

</style>