<template>
    <div class="contact_banner_box"></div>
  </template>
  
  <script>
  export default {
  
  }
  </script>
  
  <style scoped>
  .contact_banner_box {
      widows: 100%;
      height: 575px;
      background-image: url('../../assets/images/lianxiwomen-banner.png');
      background-repeat: no-repeat;
      background-size: cover;
  }
  </style>