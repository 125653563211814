<template>
    <div class="mobile_introduct_container">
        <p class="mobile_jiagou_title">基于ForceCon-DTwin数字孪生的工艺规划</p>
        <p class="mobile_jiagou_desc">
            工艺规程是产品制造工艺过程和操作方法的技术文件，是一切有关生产人员都应严格执行、认真贯彻的纪律性文件，是进行产品生产准备、生产调度、工人操作和质量检验的依据。数字孪生驱动的工艺规划指通过建立超高拟实度的产品、资源和工艺流程等虚拟仿真模型，以及全要素、全流程的虚实映射，真正实现面向生产现场的工艺设计与持续优化。在ForceCon-DTwin数字孪生平台驱动的工艺设计模式下，虚拟空间的仿真模型与物理空间的实体相互映射，形成虚实共生的迭代协同优化机制。
        </p>
        <p class="mobile_jiagou_title">基于ForceCon-DTwin数字孪生的数据标准</p>
        <img class="mobile_jiagou_img" src="../../../../assets/images/hexingongneng1.png" alt="">
        <img class="mobile_jiagou_img" src="../../../../assets/images/hexingongneng2.png" alt="">
        <p class="mobile_jiagou_title">
            基于ForceCon-DTwin数字孪生的连接与集成标准
        </p>
        <img class="mobile_jiagou_img" src="../../../../assets/images/hexingongneng3.png" alt="">
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.mobile_introduct_container {
    padding: 80.1px 61.41px;
}

.mobile_jiagou_title {
    font-size: 74.76px;
    font-family: Source Han Sans CN;
    font-weight: 300;
    color: #FFFFFF;
    /* line-height: 48px; */
    margin-top: 66.75px;
    &:nth-child(1) {
        margin-top: 0;
    }
}

.mobile_jiagou_desc {
    font-size: 64.08px;
    font-family: Source Han Sans CN;
    font-weight: 300;
    color: #B2B2B2;
    line-height: 106.8px;
    margin-top: 66.75px;
    text-indent: 2em;
}

.mobile_jiagou_img {
    width: 1815.6px;
    height: auto;
    margin-top: 66.75px;
}</style>