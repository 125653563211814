<template>
  <div class="ui_explain_container">
    <!-- <p class="title">UI搭建</p> -->
    <div class="img_box">
      <img src="../../assets/images/kaifazhong.png" alt="" />
    </div>
    <p class="desc">正在开发中...</p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.title {
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
}
.img_box {
  width: 100%;
  height: 262px;
  text-align: center;
  margin-top: 54px;
}
.img_box img {
  width: 450px;
  height: 262px;
}
.desc {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 300;
  color: #cccccc;
  margin-top: 24px;
  text-align: center;
}
</style>