<template>
    <div class="warn_message_container">
        <el-dialog :visible.sync="isShow" width="1200px" :lock-scroll="true" :show-close="false">
            <p class="warn_message_title">隐私政策</p>
            <div class="message_box">
                <p>本政策仅适用于力控科技的产品或服务。</p>
                <p>最近更新日期：2022年12月。</p>
                <p>如果您有任何疑问、意见或建议，请通过以下联系方式与我们联系：</p>
                <p>• 电子邮件：market@sunwayland.com.cn</p>
                <p>• 电 话：400-898-8181</p>
                <p>本政策将帮助您了解以下内容：</p>
                <p>1. 我们如何收集和使用您的个人信息</p>
                <p>2. 我们如何使用 Cookie</p>
                <p>3. 我们如何共享、转让、公开披露您的个人信息</p>
                <p>4. 我们如何保护您的个人信息</p>
                <p>5. 您的权利</p>
                <p>6. 本政策如何更新</p>
                <p>7. 如何联系我们</p>
                <p>力控科技深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。同时，力控科技承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。
                </p>
                <p>请在使用我们的产品（或服务）前，仔细阅读并了解本《隐私政策》。</p>
                <p>一、我们如何收集和使用您的个人信息</p>
                <p>个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。
                </p>
                <p>力控科技仅会出于本政策所述的以下目的，收集您的个人信息：</p>
                <p>（一） 帮助您成为我们的用户</p>
                <p>当您在力控科技网站创建账户时，您须向我们提供用户名、密码、电子邮箱地址及手机号码。您的电子邮箱地址及手机号码用于您进行注册、登录等账户管理活动时接受验证码，并作为您与力控科技指定的联系方式之一，用来接收相关业务通知（如我们对您提出的权利诉求的响应与反馈）。
                </p>
                <p>（二） 向您提供技术服务</p>
                <p>1.当您使用我们提供的域名注册产品时，您应提供使用该产品必要的信息，包括但不限于域名持有者姓名、联系人姓名、联系人的电子邮箱、通信地址、联系电话。</p>
                <p>2.为开展内部审计、提供持续服务和保证服务质量，我们在您使用本服务期间，将记录并保存您登录和使用本服务的信息，包括但不限于您的IP地址、与我们的服务相关的日志信息。</p>
                <p>3.为响应您的帮助请求，以及改进服务质量，我们将记录并保存您在请求帮助时产生的信息，包括但不限于使用力控科技服务而产生的咨询记录、报障记录和针对用户请求的响应过程（如通信或通话记录）。</p>
                <p>力控科技仅会出于本政策所述的以下目的，使用您提交以及我们收集的用户信息：</p>
                <p>1.为了向您提供服务，我们会向您发送信息、通知或与您进行业务沟通，包括但不限于保证服务完成所必需的验证码、服务变更的消息通知、异地登录提醒等。</p>
                <p>2.为提高您使用我们及我们关联公司、合作伙伴提供服务的安全性，确保操作环境安全与识别账号异常状态，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或力控科技相关协议、规则的情况，我们可能会使用您提交以及我们收集的用户信息，并整合我们关联公司、合作伙伴合法分享的信息，来判断您账户的交易风险、进行身份验证、安全事件的检测及防范，并依法采取必要的记录、审计、分析、处置措施。
                </p>
                <p>当我们要将信息用于本政策未载明的其它用途时，会事先征求您的同意。</p>
                <p>当我们要将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。</p>
                <p>二、我们如何使用 Cookie</p>
                <p>Cookie</p>
                <p>为确保网站正常运转，我们会在您的计算机或移动设备上存储名为 Cookie 的小数据文件。Cookie 通常包含标识符、站点名称以及一些号码和字符。借助于
                    Cookie，网站能够存储您的偏好等数据，当下一次您再次访问的时候，我们将显示您需要的信息。</p>
                <p>我们不会将 Cookie 用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。有关详情，请参见 AboutCookies.org。您可以清除计算机上保存的所有
                    Cookie，大部分网络浏览器都设有阻止 Cookie
                    的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置。如需详细了解如何更改浏览器设置，请访问以下链接：IE、ChromeChrome、FirefoxFirefox、SafariSafari
                    和 OperaOpera 。
                </p>
                <p>您可以通过浏览器设置拒绝或管理Cookies。但请注意，如果停用Cookies，您有可能无法享受最佳的服务的体验，某些功能的可能性可能会受到影响。</p>
                <p>三、我们如何共享、转让、公开披露您的个人信息</p>
                <p>（一）共享</p>
                <p>我们不会与其他公司、组织和个人分享您的个人信息，但以下情况除外：</p>
                <p>1.在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。</p>
                <p>2.我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。</p>
                <p>3.与授权合作伙伴共享：仅为实现本政策中声明的目的，我们的某些服务将由受信赖的授权合作伙伴提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。
                </p>
                <p>对我们与之共享个人信息的公司、组织和个人，我们会要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。</p>
                <p>（二）转让</p>
                <p>我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
                <p>1.在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；</p>
                <p>2.在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。</p>
                <p>（三）公开披露</p>
                <p>我们仅会在以下情况下，公开披露您的个人信息：</p>
                <p>1.获得您明确同意后；</p>
                <p>2.基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。</p>
                <p>（四）共享、转让、公开披露用户信息时事先征得授权同意的例外</p>
                <p>以下情形中，共享、转让、公开披露您的用户信息无需事先征得您的授权同意：</p>
                <p>1.与国家安全、国防安全有关的；</p>
                <p>2.与公共安全、公共卫生、重大公共利益有关的；</p>
                <p>3.与犯罪侦查、起诉、审判和判决执行等有关的；</p>
                <p>4.出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
                <p>5.您自行向社会公众公开的个人信息；</p>
                <p>6.从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</p>
            </div>
            <div class="btn" @click="isShow = false">我已知晓</div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {}
    },
    computed: {
        isShow: {
            get() {
                return this.value
            },
            set(newVal) {
                this.$emit('input', newVal)
            }
        }
    }
}
</script>

<style scoped>
.modal_container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #121418;
    opacity: 0.8;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .message_container {
    width: 1200px;
    height: 960px;
    background: #2E394D;
    border-radius: 16px;
} */

.message_box {
    /* height: 741px;
    width: 1120px;
    background: pink; */
    height: 660px;
    /* border: 1px solid pink; */
    overflow-y: auto;
    margin-top: 30px;
}

.message_box p {
    margin-top: 13px;
    color: #C8CACD;
    font-size: 14px;
}

.warn_message_title {
    font-family: Source Han Sans CN;
    font-weight: 300;
    font-size: 28px;
    color: #FEFEFE;
}

.btn {
    width: 120px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #2070E5;
    border-radius: 4px;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    margin-top: 30px;
    cursor: pointer;
}
</style>
<style>
.warn_message_container .el-dialog {
    background: #2E394D !important;
    border-radius: 16px !important;
    margin-top: 0px !important;
    position: relative !important;
    top: 68px !important;
    /* height: 960px!important; */
}

.warn_message_container .el-dialog__header {
    display: none;
}

.warn_message_container .el-dialog__body {
    border-radius: 16px;
}</style>