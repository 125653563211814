import { render, staticRenderFns } from "./mobile-appliaction-case.vue?vue&type=template&id=19fafa1d&scoped=true&"
import script from "./mobile-appliaction-case.vue?vue&type=script&lang=js&"
export * from "./mobile-appliaction-case.vue?vue&type=script&lang=js&"
import style0 from "./mobile-appliaction-case.vue?vue&type=style&index=0&id=19fafa1d&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19fafa1d",
  null
  
)

export default component.exports